import { reactive, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { SsUsersRegisterErrorsParsedInterface } from '~/types/softswiss/ssUsers.response.interface';
import { CurrencyMenuItemParsedInterface } from '~/types/currencyMenu.interface';
import { RegFormInterface } from '~/types/regForm.interface';

export function useRegForm(props) {
  const form = reactive({
    email: '',
    password: '',
    // BTC is used as a fallback currency because it's mostly used by players
    currency: 'BTC',
    terms_acceptance: false,
  });

  const errors = ref({} as SsUsersRegisterErrorsParsedInterface);

  watch(
    () => form.email,
    () => {
      errors.value.email = '';
    }
  );

  watch(
    () => form.password,
    () => {
      errors.value.password = '';
    }
  );

  watch(
    () => form.currency,
    () => {
      errors.value.currency = '';
    }
  );

  watch(
    () => form.terms_acceptance,
    () => {
      errors.value.terms_acceptance = '';
    }
  );

  const store = useStore();

  const currencies = computed(
    (): CurrencyMenuItemParsedInterface[] =>
      store.getters['currencyMenu/activeCurrencies']
  );
  const regForm = computed(
    (): RegFormInterface => store.getters['regForm/formData']
  );
  const userCountry = computed((): string => store.getters['country/country']);

  const activeCurrencies = computed((): CurrencyMenuItemParsedInterface[] => {
    if (!userCountry.value || props.cryptoOnly) {
      return currencies.value.filter(({ crypto }) => !!crypto);
    }

    return currencies.value.filter(
      ({ exluded_countries }) =>
        !exluded_countries.length ||
        !exluded_countries.some((country) => country === userCountry.value)
    );
  });

  const loadLocalizedCurrencies = () => {
    if (userCountry.value) {
      const defaultCurrencyForCountry = activeCurrencies.value.find(
        (currency) =>
          currency.default_for_countries.some(
            (country) => country === userCountry.value
          )
      );

      if (props.preselectedCurrency) {
        form.currency = props.preselectedCurrency;
      } else if (defaultCurrencyForCountry?.currency_code) {
        form.currency = defaultCurrencyForCountry.currency_code;
      } else {
        form.currency = currencies.value[0]?.currency_code;
      }
    }
  };

  watch(activeCurrencies, loadLocalizedCurrencies, { immediate: true });

  return {
    form,
    errors,
    currencies,
    regForm,
    activeCurrencies,
    userCountry,
  };
}
