import * as CryptoJS from 'crypto-js';
import {
  SsUsersRegisterInterface,
  SsUsersRequestInterface,
} from '~/types/softswiss/ssUsers.request.interface';
import { SsUsersResponseInterface } from '~/types/softswiss/ssUsers.response.interface';
import { SsPlayerRegistrationResultInterface } from '~/types/softswiss/ssPlayer.response.interface';
import axios from 'axios';
import {
  SsPlayerProfileUpdateInterface,
  SsPlayerProfileUpdateRequestInterface,
} from '@/types/softswiss/ssPlayerProfileUpdate.interface';

export async function registerPlayer(
  playerData: SsUsersRegisterInterface,
  host: string,
  lang: string
): Promise<SsPlayerRegistrationResultInterface> {
  try {
    const res = await axios.post(
      `https://${host}/api/users`,
      { user: playerData } as SsUsersRequestInterface,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/vnd.softswiss.v1+json',
          'accept-language': lang,
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }
    );
    const playerInfo = res.data as SsUsersResponseInterface;

    if (playerInfo.id) {
      return { playerInfo };
    }

    return {
      playerInfo,
      errors: { result: 'login failed' },
    };
  } catch (e) {
    const errors = e?.response?.data?.errors;
    if (errors) {
      return {
        errors,
        playerInfo: {} as SsUsersResponseInterface,
      };
    } else {
      return {
        playerInfo: {} as SsUsersResponseInterface,
        errors: { result: 'unknown error' },
      };
    }
  }
}

/**
 * token for authentification of client outside the domain
 */
export function generateAuthToken(data: {
  login: string;
  password: string;
  stag: string;
  modal: string;
}): string {
  const key = String(Date.now()).substring(0, 8);

  // Encrypt
  const encryptedData = CryptoJS.AES.encrypt(
    // String(this.$route.query.auth) ||
    [data.login, data.password, data.stag, data.modal].join('\n'),
    key
  ).toString();

  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedData));
}

export async function updateProfile(
  playerData: SsPlayerProfileUpdateInterface,
  host: string,
  lang: string
): Promise<SsPlayerRegistrationResultInterface | any> {
  try {
    const payload: SsPlayerProfileUpdateRequestInterface = {
      context: 'edition',
      player: playerData,
    };

    const res = await axios.patch(`https://${host}/api/player`, payload, {
      withCredentials: true,
      headers: {
        Accept: 'application/vnd.softswiss.v1+json',
        'accept-language': lang,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    const playerInfo = res.data as SsUsersResponseInterface;

    return { playerInfo };
  } catch (e) {
    const errors = e?.response?.data?.errors;
    if (errors) {
      return { errors };
    } else {
      return {
        playerInfo: {} as SsUsersResponseInterface,
        errors: { result: 'unknown error' },
      };
    }
  }
}
