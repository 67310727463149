import { useGtm } from '@gtm-support/vue-gtm';
import { GtmPushInterface } from '~/types/gtmPush.interface';

export function useGtmPush() {
  const gtm = useGtm();

  const push = (data: GtmPushInterface) => {
    gtm.trackEvent({
      event: 'tEvent',
      category: data.vCategory,
      action: data.vAction,
      label: data.vLabel,
      value: data.vValue,
      noninteraction: false,
    });
  };

  return { push };
}
