import axios from 'axios';
import Bugsnag from '@bugsnag/js';

interface DebounceResponse {
  data: {
    debounce: {
      send_transactional: string;
    };
  };
}

export const checkEmail = async (email: string): Promise<boolean> => {
  try {
    // TODO: move debounce API data to Strapi
    const debounceKey = 'public_ay9qVWVuS3dXeW9mRkZmTzExbTZpUT09';
    const debounceApiUrl = `https://api.debounce.io/v1/?api=`;

    const { data } = (await axios.get(
      `${debounceApiUrl}${debounceKey}&email=${email}`
    )) as DebounceResponse;

    return !!parseInt(data.debounce?.send_transactional);
  } catch (e) {
    Bugsnag.notify(e);
    return true;
  }
};
