import {
  SsUsersRegisterErrorsInterface,
  SsUsersRegisterErrorsParsedInterface,
} from '~/types/softswiss/ssUsers.response.interface';

export const registrationErrorsParser = (
  registrationErrors?: SsUsersRegisterErrorsInterface
): SsUsersRegisterErrorsParsedInterface => {
  if (!registrationErrors) {
    return {};
  }

  const { profile, ...errors } = registrationErrors;
  const errorsList = { ...(profile || {}), ...(errors || {}) };
  const result: { [key: string]: string } = {};
  Object.keys(errorsList).forEach((key) => {
    // @ts-ignore
    result[key] = Object.values(errorsList[key]).join(',');
  });
  return result;
};
