import { useStore } from 'vuex';
import { computed, ref, onMounted, nextTick, Ref } from 'vue';
import { StrapiImageInterface } from '~/types/strapiImage.interface';
import { PromoLoaderInterface } from '~/types/promoLoader.interface';
import { PromoPageInterface } from '~/types/promoPage.interface';

export function useCommonPromo(
  props: { pageData: PromoPageInterface },
  userCurrency?: Ref<string>
) {
  /**
   * variable for stop render of some big elements for fatser page render
   */
  const renderTimeout = ref(true);

  onMounted(() => {
    // after draw actions
    nextTick(() => {
      window.setTimeout(() => {
        renderTimeout.value = false;
      }, 1000);
    });
  });

  const store = useStore();
  const isDesktop = computed(() => store.getters['platform/isDesktop']);
  const isMobile = computed(() => store.getters['platform/isMobile']);
  const isTablet = computed(() => store.getters['platform/isTablet']);

  const userCountry = computed((): string => store.getters['country/country']);

  const lang = computed(() => props.pageData.lang);

  const videoBanner = computed((): StrapiImageInterface => {
    if (
      isMobile.value &&
      props.pageData.params[0].banner_mobile?.mime?.includes('video')
    ) {
      return props.pageData.params[0].banner_mobile;
    } else if (
      (isTablet.value || isDesktop.value) &&
      props.pageData.params[0].banner_desktop?.mime?.includes('video')
    ) {
      return props.pageData.params[0].banner_desktop;
    }
    return null;
  });

  const bannerDesktop = computed((): StrapiImageInterface | null => {
    if (
      props.pageData.params[0].banner_desktop?.mime?.includes('image') &&
      (isDesktop.value || isTablet.value)
    ) {
      return props.pageData.params[0].banner_desktop || null;
    }

    return null;
  });

  const bannerMobile = computed((): StrapiImageInterface | null => {
    if (
      props.pageData.params[0].banner_mobile?.mime?.includes('image') &&
      isMobile.value
    ) {
      return props.pageData.params[0].banner_mobile || null;
    }

    return null;
  });

  const bannerCssVariables = computed(
    (): {
      '--bg-img-desktop'?: string;
      '--bg-img-mobile'?: string;
    } => {
      const css = {};
      if (isMobile.value && !videoBanner.value) {
        css['--bg-img-mobile'] = `url(${bannerMobile.value?.url || ''})`;
      }
      if ((isTablet.value || isDesktop.value) && !videoBanner.value) {
        css['--bg-img-desktop'] = `url(${bannerDesktop.value?.url || ''})`;
      }
      return css;
    }
  );

  const logoImg = computed((): StrapiImageInterface | null => {
    return props.pageData.params[0]?.logo || null;
  });

  /**
   * Getting data for promo page loader
   */
  const promoLoader = computed((): PromoLoaderInterface => {
    return {
      loadingText: props.pageData.params[0].loading || '',
      successText: props.pageData.params[0].loading_success || '',
      welcomeText: props.pageData.params[0].welcome || '',
      loadingIcon: props.pageData.params[0].loading_icon?.url || '',
    };
  });

  const onlyCrypto = computed(
    (): boolean => props.pageData.params[0].only_crypto
  );

  const currency = computed((): string | null => {
    if (props.pageData.params[0]?.currency_menu?.currency_code) {
      return props.pageData.params[0].currency_menu.currency_code;
    }

    return null;
  });

  const secondTitle = computed((): string | null => {
    const titleParams = props.pageData.params[0]?.second_title_multi;
    if (titleParams && Array.isArray(titleParams)) {
      const country = userCountry.value?.toLowerCase() || '';
      const currency = userCurrency.value?.toLowerCase() || '';

      if (titleParams?.length) {
        return (
          titleParams.find((b) => {
            return (
              b.country?.toLowerCase() === country &&
              b.currency?.toLowerCase() === currency
            );
          })?.value ||
          titleParams.find((b) => {
            return b.country?.toLowerCase() === country && !b.currency;
          })?.value ||
          titleParams.find((b) => {
            return b.currency?.toLowerCase() === currency && !b.country;
          })?.value ||
          titleParams.find((b) => {
            return !b.currency && !b.country;
          })?.value ||
          null
        );
      } else {
        return null;
      }
    } else {
      return props.pageData.params[0].second_title;
    }
  });

  const targetHost = computed((): string => {
    return '';
  });

  const theme = computed((): string => props.pageData.params[0].theme);

  const dataPlatformAttr = computed(() => {
    return {
      'data-platform-mobile': isMobile.value || null,
      'data-platform-tablet': isTablet.value || null,
      'data-platform-desktop': isDesktop.value || null,
    };
  });

  return {
    dataPlatformAttr,
    isDesktop,
    isMobile,
    isTablet,
    lang,
    theme,
    targetHost,
    currency,
    onlyCrypto,
    promoLoader,
    logoImg,
    bannerCssVariables,
    secondTitle,
    videoBanner,
    bannerDesktop,
    bannerMobile,
    renderTimeout,
  };
}
